import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container } from '@mui/material'

import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import TextSection from 'public/components/organisms/sections/TextSection'

interface Props {
  data: Queries.NotFoundQuery
}

const NotFound = ({ data }: Props) => {
  const page = data.datoCmsLandingPage

  return (
    <Layout
      metadata={{
        seoTags: page?.seoMetaTags,
      }}
    >
      <PageTitleHero
        mainHeading={page?.heroMainHeading}
        smallHeading={page?.heroSmallHeading}
        imagePortrait={page?.imagePortrait.gatsbyImageData}
        imageLandscape={page.imageLandscape.gatsbyImageData}
      />
      <TextSection
        leadText={page.textSectionHeroText}
        smallHeading={page.textSectionSmallHeading}
        text={page.textSectionBodyNode.childMarkdownRemark.html}
        containerProps={{ maxWidth: 'md' }}
      />
      <Container maxWidth="md">
        <Box pb={{ xs: 8, xl: 12 }} bgcolor="white" position="relative">
          <InternalButtonLink
            slug=""
            variant="contained"
            color="primary"
            sizeVariant="xl"
          >
            Visit our homepage
          </InternalButtonLink>
        </Box>
      </Container>
    </Layout>
  )
}

export default NotFound

export const pageQuery = graphql`
  query NotFound {
    datoCmsLandingPage(originalId: { eq: "2414032" }) {
      ...LandingPageTitleHero
      textSectionHeroText
      textSectionSmallHeading
      textSectionBodyNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
